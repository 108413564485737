import { nutrionistSchema } from "../../utils/nutritionistSchema";

let { parseFormField } = require("services/airtable");

export const useSectionFormFields = ({ formFields }) => {
	return {
		sectionFormFields: parseFormField(formFields),
		validationSchema: nutrionistSchema({ fields: formFields }),
	};
};
