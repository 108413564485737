import * as Yup from "yup";
import {
	getCustomValidation,
	getDefaultValidation,
} from "../../../services/validations";

export const nutrionistSchema = ({ fields }) => {
	let validationSchema = Yup.object();

	for (let field of fields) {
		let fieldValidation = getDefaultValidation({ field });
		fieldValidation = getCustomValidation({ field, fieldValidation });

		validationSchema = validationSchema.concat(
			Yup.object().shape({
				[field.name]: fieldValidation,
			})
		);
	}

	return validationSchema;
};
